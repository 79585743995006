<template>
    <div class="user-edit" style="max-width: 1000px; margin: auto">
        <edit :api="api" :fs="fs" :id="id" @saved="saved" back="/admin/users" title="user" v-if="fs"></edit>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Edit from "@/components/Edit";
import { states } from "@/config";
import VendorProfileVue from "../Vendor/VendorProfile.vue";
export default {
    props: {
        id: {
            type: String
        },
        mode: {
            type: String,
            default: "user" // user staff branch
        }
    },
    components: {
        Edit
    },
    data() {
        return {
            editingUser: null
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user",
            hasRole: "user/hasRole",
            paymentSetting: "setting/payment"
        }),
        api() {
            if (this.mode == "branch" && !this.id) {
                return "users/fromMasterVendor";
            }
            if (this.mode == "staff" && !this.id) {
                return "users/fromVendor";
            }
            if (this.mode == "admin" && !this.id) {
                return "users/fromAdmin";
            }
            return "users";
        },
        fs() {
            let userFields = [
                { heading: "Email", data: "email", type: this.id ? "readonly" : "text", validate: "required|email" },
                { heading: "Name", data: "name", type: "text" },
                { heading: "Company/Organization", data: "company", type: "text" },
                { heading: "Role", data: "role", type: this.user.role == "admin" ? "select" : "readonly", options: ["user", "manager", "admin"] },
                { heading: "Avatar", data: "avatar", type: "image", round: true, folder: "user" },
                this.id && this.editingUser && this.editingUser.role == "manager"
                    ? {
                          heading: "Permissions",
                          data: "report_permissions",
                          type: "checkboxs",
                          options: [
                              { heading: "View Monthly Bookings", data: "view_monthly_bookings" },
                              { heading: "View Vendor Totals", data: "view_vendor_totals" }
                          ],
                          default: {},
                          when: "edit"
                      }
                    : null,
                { heading: "Active", data: "active", type: "boolean", action: true }
            ].filter(Boolean);

            let staffFields = [
                { heading: "Email", data: "email", type: this.id ? "readonly" : "text", validate: "required|email" },
                { heading: "Name", data: "name", type: this.id ? "readonly" : "text", validate: "required" },
                { heading: "Company/Organization", data: "company", type: "text" },
                { heading: "Role", data: "role", type: "readonly", default: "staff" },
                { heading: "Avatar", data: "avatar", type: "image", round: true, folder: "user" },
                { heading: "Vendor", data: "staff_vendor_name", type: "readonly", when: "list" },
                {
                    heading: "Permissions",
                    data: "permissions",
                    type: "checkboxs",
                    options: [
                        { heading: "Edit Product", data: "edit_product" },
                        { heading: "View Product Review", data: "view_product_review" },
                        { heading: "Edit Order", data: "edit_order" },
                        { heading: "View Order Amount", data: "view_order_amount" },
                        { heading: "View Order List Amount", data: "view_order_list_amount" },
                        { heading: "Edit Order Email", data: "edit_order_email" },
                        { heading: "Edit Order Pos Payment", data: "edit_order_payment_pos" },
                        { heading: "View Report", data: "view_report" },
                        { heading: "Reply QA", data: "reply_qa" }
                    ],
                    default: {},
                    when: "edit"
                },
                { heading: "Active", data: "active", type: "boolean", action: true }
            ].filter(Boolean);

            let vendorFields = [
                { heading: "Email", data: "email", type: this.id ? "readonly" : "text", validate: "required|email" },
                { heading: "Name", data: "name", type: this.id ? "readonly" : "text", validate: "required" },
                { heading: "Company/Organization", data: "company", type: "text" },
                { heading: "Role", data: "role", type: "readonly", default: "vendor" },
                { heading: "Avatar", data: "avatar", type: "image", round: true, folder: "user" },
                { heading: "Logo", data: "vendor.logo", type: "image", folder: "vendor" },
                { heading: "Business", data: "vendor.business", type: "text" },
                { heading: "Type", data: "vendor.vtype", type: "select", options: ["Rental Company", "Service Provider", "Ecommerce"] },
                { heading: "Vendor Name", data: "vendor.name", type: "text" },
                { heading: "Email", data: "vendor.email", type: "text" },
                { heading: "Phone", data: "vendor.phone", type: "text" },
                { heading: "Country", data: "vendor.country", type: "select", options: ["United States"] },
                { heading: "State", data: "vendor.state", type: "select", options: Object.keys(states).sort((a, b) => (a > b ? 1 : -1)) },
                { heading: "City", data: "vendor.city", type: "text" },
                { heading: "Address", data: "vendor.address", type: "text" },
                { heading: "Zip", data: "vendor.zip", type: "text" },
                { heading: "Welcome", data: "vendor.welcome", type: "textarea" },
                { heading: "App Fee %", data: "vendor.percent", type: this.hasRole("manager") ? "number" : "readonly", validate: "min_value: 0|max_value: 100" },
                { heading: "Off Hour Addition Fee", data: "vendor.offHourAdditionFee", type: "number", validate: "min_value: 0" }
            ];

            if (this.paymentSetting.value && this.paymentSetting.value.use_tilled) {
                vendorFields.push({ heading: "EzTimePay Connect", data: "tilledConnectId", type: "readonly" });
                vendorFields.push({ heading: "EzTimePay Id", data: "tilledId", type: "readonly" });
            }

            if (this.paymentSetting.value && this.paymentSetting.value.use_stripe) {
                vendorFields.push({ heading: "Stripe Connect", data: "stripeConnectId", type: "readonly" });
                vendorFields.push({ heading: "Stripe Id", data: "stripeId", type: "readonly" });
            }

            if (this.hasRole("manager")) {
                vendorFields.push({ heading: "Active", data: "active", type: "boolean" });
            }

            vendorFields = vendorFields.filter(Boolean);

            let branchFields = [
                { heading: "Email", data: "email", type: this.id ? "readonly" : "text", validate: "required|email" },
                { heading: "Name", data: "name", type: this.id ? "readonly" : "text", validate: "required" },
                { heading: "Company/Organization", data: "company", type: "text" },
                { heading: "Role", data: "role", type: "readonly", default: "vendor" },
                { heading: "Avatar", data: "avatar", type: "image", round: true, folder: "user" },
                { heading: "Logo", data: "vendor.logo", type: "image", folder: "vendor" },
                { heading: "Business", data: "vendor.business", type: "text" },
                { heading: "Type", data: "vendor.vtype", type: "select", options: ["Rental Company", "Service Provider", "Ecommerce"] },
                { heading: "Vendor Name", data: "vendor.name", type: "text" },
                { heading: "Email", data: "vendor.email", type: "text" },
                { heading: "Phone", data: "vendor.phone", type: "text" },
                { heading: "Country", data: "vendor.country", type: "select", options: ["United States"] },
                { heading: "State", data: "vendor.state", type: "select", options: Object.keys(states).sort((a, b) => (a > b ? 1 : -1)) },
                { heading: "City", data: "vendor.city", type: "text" },
                { heading: "Address", data: "vendor.address", type: "text" },
                { heading: "Zip", data: "vendor.zip", type: "text" },
                { heading: "Welcome", data: "vendor.welcome", type: "textarea" },
                { heading: "App Fee %", data: "vendor.percent", type: this.hasRole("manager") ? "number" : "readonly", validate: "min_value: 0|max_value: 100" },
                { heading: "Off Hour Addition Fee", data: "vendor.offHourAdditionFee", type: "number", validate: "min_value: 0" },

                this.hasRole("manager") && { heading: "Active", data: "active", type: "boolean" }
            ].filter(Boolean);

            if (!this.id) {
                if (this.mode == "vendor") {
                    return vendorFields;
                }
                if (this.mode == "branch") {
                    return branchFields;
                }
                if (this.mode == "staff") {
                    return staffFields;
                }
            }

            if (this.id && this.editingUser) {
                if (this.editingUser.role == "vendor" && !this.editingUser.master_vendor_id) {
                    return vendorFields;
                }
                if (this.editingUser.role == "vendor" && this.editingUser.master_vendor_id) {
                    return branchFields;
                }
                if (this.editingUser.role == "staff") {
                    return staffFields;
                }
            }

            return userFields;
        }
    },
    methods: {
        async loadUser() {
            if (this.id) {
                let user = await this.$store.dispatch("crud/get", {
                    api: `users/${this.id}`
                });
                this.editingUser = user;
            }
        },
        saved(result) {
            console.info("saved", result);
            let user = result.user || result;
            if (user) {
                if (user.role == "admin" || user.role == "manager") {
                    this.$router.push("/admin/users/admins");
                } else if (user.role == "vendor" && !user.master_vendor_id) {
                    this.$router.push("/admin/users/vendors");
                } else if (user.role == "vendor" && user.master_vendor_id) {
                    this.$router.push("/admin/users/branches");
                } else if (user.role == "staff") {
                    this.$router.push("/admin/users/staffs");
                }
            } else {
                this.$router.push("/admin/users");
            }
        }
    },
    async created() {
        await this.$store.dispatch("setting/refreshPayment");
    },
    mounted() {
        this.loadUser();
    }
};
</script>
<style lang="less">
.user-edit {
    .form {
        .md-input-container {
            .md-checkbox-label {
                width: 200px;
            }
        }
    }
}
</style>
