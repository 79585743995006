<template>
    <div>
        <ul class="common-pagination" v-show="totalPage > 1" @click="handlePagerClick">
            <li title="Previous Page" class="common-pagination-item  common-pagination__prev iconfont icon-arrow1" :class="{'common-pagination__disabled':pageNo === 1}"></li>
            <li class="common-pagination-item" :class="{active: pageNo === 1}">1</li>
            <li class="common-pagination-item common-pagination__more iconfont icon-more" v-show="showPrevMore"></li>
            <li :title="pager" v-for="pager in pagers" :key="pager" :class="{ active: pageNo === pager }" class="common-pagination-item">{{ pager }}</li>
            <li class="common-pagination-item  common-pagination__more iconfont icon-more" v-show="showNextMore"></li>
            <li class="common-pagination-item" :class="{active: pageNo === totalPage}">{{totalPage}}</li>
            <li title="Next Page" class="common-pagination-item  common-pagination__next iconfont icon-arrow" :class="{'common-pagination__disabled':pageNo === totalPage}"></li>
        </ul>
    </div>
</template>
<script>
/**
 * @author Gu
 * @description Pagination comp
 * @createTime 2018-02-11
 */
export default {
    name: "common-pagination",
    props: {
        totalPage: {
            default: 0,
            type: Number
        },
        pageSize: {
            default: 10,
            type: Number
        },
        pageNo: {
            default: 1,
            type: Number
        },
        showQuickJumper: {
            default: true,
            type: Boolean
        }
    },
    data() {
        return {
            showPrevMore: false,
            showNextMore: false
        };
    },
    computed: {
        pagers() {
            const pagerCount = 5;
            const { pageNo, totalPage } = this;
            let showPrevMore = false;
            let showNextMore = false;
            if (totalPage > pagerCount) {
                if (pageNo > pagerCount - 3) {
                    showPrevMore = true;
                }
                if (pageNo < totalPage - 3) {
                    showNextMore = true;
                }
            }
            const array = [];
            if (showPrevMore && !showNextMore) {
                const startPage = totalPage - (pagerCount - 2);
                for (let i = startPage; i < totalPage; i++) {
                    array.push(i);
                }
            } else if (!showPrevMore && showNextMore) {
                for (let i = 2; i < pagerCount; i++) {
                    array.push(i);
                }
            } else if (showPrevMore && showNextMore) {
                const offset = Math.floor(pagerCount / 2) - 1;
                for (let i = pageNo - offset; i <= pageNo + offset; i++) {
                    array.push(i);
                }
            } else {
                for (let i = 2; i < totalPage; i++) {
                    array.push(i);
                }
            }
            this.showPrevMore = showPrevMore;
            this.showNextMore = showNextMore;
            return array;
        }
    },
    watch: {
        // pageSize() {
        //     this.$emit("pagination", { page: this.pageNo, size: this.pageSize });
        // },
        // pageNo() {
        //     this.$emit("pagination", { page: this.pageNo, size: this.pageSize });
        // }
    },
    methods: {
        handlePagerClick(event) {
            const { className, tagName, textContent } = event.target;

            if (tagName === "UL") {
                return;
            }

            let newPage = +textContent;
            const { pageNo, totalPage } = this;

            if (/prev/gi.test(className)) {
                newPage = pageNo - 1;
            }

            if (/next/gi.test(className)) {
                newPage = pageNo + 1;
            }

            if (!isNaN(newPage)) {
                if (newPage < 1) {
                    newPage = 1;
                }
                if (newPage > totalPage) {
                    newPage = totalPage;
                }
            }
            if (newPage !== pageNo) {
                console.info(newPage,pageNo)
                this.$emit("pagination", { page: newPage, size: this.pageSize });
            }
        }
    }
};
</script>
<style lang="less">
.common-pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;

    .common-pagination-item {
        margin: 0;
        border-radius: 4px;
        user-select: none;
        width: 32px;
        height: 32px;
        line-height: 30px;
        text-align: center;
        list-style: none;
        vertical-align: middle;
        text-align: center;
        border: 1px solid #d9d9d9;
        background-color: #fff;
        margin-right: 8px;
        font-family: Arial;
        outline: 0;
        cursor: pointer;

        &:hover,
        &.active {
            color: #ffa726;
            border-color: #ffa726;
            font-weight: 500;
        }
    }

    .common-pagination__more {
        border: none;
        cursor: default;
        color: rgba(0, 0, 0, 0.25);
        font-size: 20px;
        &:hover {
            color: rgba(0, 0, 0, 0.25);
        }
    }

    .common-pagination__disabled {
        color: rgba(0, 0, 0, 0.25);
        cursor: not-allowed;

        &:hover {
            border-color: #d9d9d9;
            color: rgba(0, 0, 0, 0.25);
        }
    }

    @media (max-width: 600px) {
        .common-pagination-item {
            width: 27px;
            height: 27px;
            line-height: 25px;
        }
    }
}

.vendorMode {
    .common-pagination {
        .common-pagination-item {
            &:hover,
            &.active {
                color: #38f;
                border-color: #38f;
                font-weight: 500;
            }
        }
    }
}
</style>
