<template>
    <div class="bg vendor-detail">
        <div v-if="vendor" class="vendor-profile">
            <md-card v-if="vendor">
                <md-card-header>
                    <div class="head-left">
                        <div>
                            <h1 class="md-title">{{vendor.name}}</h1>
                            <router-link class="md-raised" tag="md-button" :to="'/shop?q='+vendor_name">View Seller's Items</router-link>
                        </div>
                        <div v-if="vendor.vendor && vendor.vendor.welcome">
                            <pre>{{vendor.vendor.welcome}}</pre>
                        </div>
                    </div>
                    <div class="head-right">
                        <p>Have you bought something from the seller?</p>
                        <p class="question">
                            Why should I review a seller?
                            <md-tooltip md-direction="bottom">By leaving vendor reviews you provide feed back to the community for people like you to better assess a vendors performance and quality. Your rating contributes to the seller’s overall rating. So please be fair with your appraisal.</md-tooltip>
                        </p>
                    </div>
                </md-card-header>
                <md-card-content>
                    <vendor-reviews :vendor="vendor"></vendor-reviews>
                </md-card-content>
            </md-card>
        </div>
        <div v-else style="text-align:center;color:white;padding:100px 0">
            Vendor Not Found
        </div>
    </div>
</template>
<script>
// Removed
import VendorReviews from "@/components/Vendor/VendorReviews";
import { mapGetters, mapMutations } from "vuex";
export default {
    props: ["vendor_name"],
    components: {
        VendorReviews
    },
    data() {
        return {
            vendor: null
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user",
            hasRole: "user/hasRole"
        })
    },
    methods: {
        async getVendor() {
            var result = await this.$store.dispatch("crud/get", {
                api: `users/vendor`,
                params: {
                    name: this.vendor_name
                }
            });
            if (!result.active) {
                this.$store.commit("setMessage", "vendor not active");
                return;
            }
            this.vendor = result;
        }
    },
    mounted() {
        this.getVendor();
    }
};
</script>
<style lang="less">
.vendor-detail {
    p {
        margin: 0;
    }
    .vendor-profile {
        background: white;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding-top: 10px;
        .md-card-header {
            display: flex;
            justify-content: space-between;
        }
    }
    .head-right {
        background: #eee;
        padding: 10px;
        .question {
            color: rgb(33, 150, 243);
        }
        .md-tooltip {
            width: 300px;
            white-space: normal;
            height: auto;
            color: #000;
            background: rgb(238, 238, 238);
            font-size: 12px;
        }
        pre {
            white-space: pre-wrap;
        }
    }

    @media (max-width: 600px) {
        .vendor-profile {
            .md-card-header {
                flex-direction: column;
            }
        }
    }
}
</style>
